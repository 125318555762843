import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import router from './router'
import store from './store'

import * as Echarts from 'echarts';

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import VueQuillEditor from 'vue-quill-editor'
import Vant from 'vant';
import 'vant/lib/index.css';

import TcVod from 'vod-js-sdk-v6'

Vue.prototype.$TcVod = TcVod


Vue.prototype.$Echarts = Echarts;

Vue.config.productionTip = false

Vue.use(ElementUI);
Vue.use(VueQuillEditor)
Vue.use(Vant);
// Vue.use(TcVod);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
